@tailwind base;
@tailwind components;

@tailwind utilities;

@layer base {
	@font-face {
		font-family: "cera-normal";
		font-weight: "100";
		font-size: "24px";
		src: url("./fonts/Cera/Cera Pro Medium.otf");
	}

	@font-face {
		font-family: "cera-black";
		font-weight: "100";
		font-size: "24px";
		src: url("./fonts/Cera/Cera Pro Black.otf");
	}

	/* .p-tooltip {
		@apply min-w-max;
	} */

	/* .green-tooltip.p-tooltip .p-tooltip-arrow {
		@apply border-t border-akgreen !important;
	}

	.green-tooltip.p-tooltip .p-tooltip-text {
		@apply bg-akgreen !important;
	}

	.light-tooltip.p-tooltip .p-tooltip-arrow {
		@apply border-t border-gray-900 !important;
	} */

	/* .light-tooltip.p-tooltip .p-tooltip-text {
		@apply bg-gray-100 text-gray-600 !important;
	} */

	@font-face {
		font-family: "cera-bold";
		src: url("./fonts/Cera/Cera Pro Black.otf");
	}

	h1 {
		@apply text-3xl;
	}
	h2 {
		@apply text-2xl;
	}
	h3 {
		@apply text-xl;
	}
	h4 {
		@apply text-lg;
	}

	.p-divider-content {
		@apply bg-gray-100 border shadow-md text-sm rounded font-sans px-1 !important;
	}

	.p-dialog-content {
		@apply font-sans text-sm font-normal !important;
	}

	.admin-profile .p-button {
		@apply bg-gray-100 border-0 text-gray-600 !important;
	}

	.p-chart canvas {
		@apply max-h-96 !important;
	}

	.not-full .p-sidebar {
		@apply max-w-max !important;
	}

	/* .p-sidebar-header {
    @apply flex justify-between !important;
  } */

	.mobile-menu .p-button {
		@apply bg-gray-100 border-0 text-akgreen !important;
	}

	.p-paginator {
		@apply bg-white py-0 !important;
	}

	.p-paginator-page {
		@apply bg-akgreener text-gray-100 rounded-full !important;
	}

	.p-highlight {
		@apply bg-gray-300 text-akgreener ring-2 ring-akgreener !important;
	}

	.columns .column:not(.buttons) {
		@apply hidden !important;
	}

	.columns .is-3 {
		@apply flex flex-row !important;
	}

	#popup_menu {
		@apply w-3/5;
	}

	#about-akello .p-dialog-header {
		@apply rounded-t-3xl;
	}

	#mobileMenu .p-splitbutton-defaultbutton,
	#desktopMenu .p-splitbutton-defaultbutton {
		@apply bg-white text-akgreener border border-akgreener font-sans rounded-l-full border-r-0;
	}

	#mobileMenu .p-splitbutton-menubutton,
	#desktopMenu .p-splitbutton-menubutton {
		@apply bg-white text-akgreener border border-akgreener font-sans rounded-r-full border-l-0;
	}

	#mobileMenu .p-button-success {
		@apply bg-gray-100 border border-akgreen rounded-full !important;
	}

	.p-splitbutton {
		@apply rounded-full border border-akgreenest font-sans !important;
	}

	.p-splitbutton button {
		@apply bg-transparent !important;
	}
}
